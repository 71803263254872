import axios from "axios";
import { $getRoot, createEditor } from "lexical";
import { v4 as uuid } from "uuid";
import { TESTING } from "../../../utils/constants";
import { $createSectionNode } from "../nodes/SectionNode";
import { GlobalListHandler } from "../plugins/ListExtendedPlugin/GlobalListHandler";
import { editorConfig as config } from "../utils";
import { headersAndFootersIsEmpty } from "./utils/headersAndFootersIsEmpty";
import { parseBlocksAndPush } from "./utils/parseBlocksAndPush";
import { removeAllContentControlsFromSfdt } from "./utils/removeAllContentControlsFromSfdt";
import { removeAllCrossReferencesAndMailMergeFieldsFromSfdt } from "./utils/removeAllCrossReferencesAndMailMergeFieldsFromSfdt";

/**
 * @typedef {import('../types/sfdt').Sfdt & { defaultSfdt?: boolean }} ConversionSfdt
 */

/**
 * Imports SFDT (JSON) and converts to Lexical.
 *
 * @param {ConversionSfdt} sfdt - This is modified during the conversion process.
 * @param {{ user: *; settings: *; parties: *; } | *} metadata
 * @returns {Promise<import('../types/agreementTypeWithLexicalState').AgreementTypeWithLexicalState>}
 */
export default async function convertSfdtToLexical(
  sfdt,
  { user, settings, parties, users: organizationUsers = [], ...rest }
) {
  // Clone original styles and abstract lists so that we can restore them at the end.
  const originalStyles = sfdt.styles
    ? JSON.parse(JSON.stringify(sfdt.styles))
    : undefined;
  const originalAbstractLists = sfdt.abstractLists
    ? JSON.parse(JSON.stringify(sfdt.abstractLists))
    : undefined;

  if (!sfdt.defaultSfdt && process.env.NODE_ENV !== "production") {
    console.info("%cImported SFDT:", "color: gold");
    console.info(JSON.parse(JSON.stringify(sfdt)));
  }

  const editor = createEditor(config);

  // TODO: We can leave this as null for now since we will revisit this logic when we implement clauses.
  const agrTypeID = null;
  /** @type {Record<string, *>} */
  const metadata = {};
  /** @type {*} */
  let agreement = { collabs: [] };

  const { sections } = sfdt;
  if (!sections) throw new Error("Document does not have sections.");

  removeAllCrossReferencesAndMailMergeFieldsFromSfdt(sfdt);
  removeAllContentControlsFromSfdt(sfdt);

  // Check for high level metadata.
  for (const section of sections) {
    // SFDT blocks are equivalent to Lexical paragraphs.
    const { blocks } = section;
    if (blocks) {
      // Iterate section blocks.
      for (const block of blocks) {
        if (metadata) {
          if (!metadata.agreementId) {
            const agreementIdBookmark = block.inlines?.find(
              (x) => x.bookmarkType === 0 && x.name?.startsWith("_a_")
            );
            if (agreementIdBookmark) {
              const agreementId = agreementIdBookmark.name?.split("_a_")[1];

              if (!agreementId) {
                throw new Error("Malformed metadata agreement ID.");
              }

              metadata.agreementId = agreementId;
            }
          }

          if (!metadata.orgId) {
            const orgIdBookmark = block.inlines?.find(
              (x) => x.bookmarkType === 0 && x.name?.startsWith("_o_")
            );
            if (orgIdBookmark) {
              const orgId = orgIdBookmark.name?.split("_o_")[1];

              if (!orgId) {
                throw new Error("Malformed metadata org ID.");
              }

              metadata.orgId = orgId;
            }
          }

          if (!metadata.entityId) {
            const entityIdBookmark = block.inlines?.find(
              (x) => x.bookmarkType === 0 && x.name?.startsWith("_e_")
            );
            if (entityIdBookmark) {
              const entityId = entityIdBookmark.name?.split("_e_")[1];

              if (!entityId) {
                throw new Error("Malformed metadata entity ID.");
              }

              metadata.entityId = entityId;
            }
          }

          if (!metadata.partyId) {
            const partyIdBookmark = block.inlines?.find(
              (x) => x.bookmarkType === 0 && x.name?.startsWith("_p_")
            );
            if (partyIdBookmark) {
              const partyId = partyIdBookmark.name?.split("_p_")[1];

              if (!partyId) {
                throw new Error("Malformed metadata party ID.");
              }

              metadata.partyId = partyId;
            } else {
              //@ts-ignore
              const party = parties?.find((el) => el.orgID === user.orgID);
              if (party) {
                metadata.partyId = party.partyID;
              }
            }
          }
        }
      }
    }
  }

  // Get merge fields.
  if (metadata && metadata.agreementId && settings) {
    if (!metadata.orgId || !metadata.entityId || !metadata.partyId) {
      throw new Error("Missing document metadata.");
    }

    const getAgreementResponse = await axios.get(
      `${settings.api}agr/${metadata?.agreementId}/collaboratorsUsers`
    );
    agreement = getAgreementResponse.data.data;

    // Get merge fields for current document and versions.
    const getDocumentMergeFieldsResponse = await axios.get(
      `${settings.api}document/${metadata.agreementId}/mergeFields/all`
    );
    if (!getDocumentMergeFieldsResponse) {
      throw new Error("Error getting response.");
    }

    metadata.mergeFields = getDocumentMergeFieldsResponse.data.data;
    if (!metadata.mergeFields) throw new Error("Error getting merge fields.");

    // console.info("%cDocument Merge Fields:", "color: gold");
    // console.info(metadata.mergeFields);
  }

  // Get workflows (comments, private comments, etc.).
  if (agreement && metadata.orgId && settings) {
    const response = await axios.get(
      `${settings.api}agrv/${agreement._id}/org/${metadata.orgId}/latest`
    );
    metadata.workflows = response.data.data.workflows;
  }

  // Reset global list handler instance so we start from scratch.
  GlobalListHandler.reset();

  // The reason why we need to wrap this logic in a promise is to make sure we skip to the next iteration of
  // the event loop. Otherwise, when we try to retrieve the editor state after the update, it is empty. It
  // seems that even using a "headless" version of the editor relies on some internal rendering mechanism and
  // this is a way of working around that issue.
  //
  // We also need to use this approach to properly catch and handle errors. Using the option { discrete: true }
  // does not work.
  await new Promise((resolve, reject) => {
    editor.update(() => {
      try {
        const root = $getRoot();
        // This map contains the high level comments from the SFDT with the comment ID as the key.
        /** @type {Map<string, import("../types/sfdt").Comment>} */
        const sfdtCommentsMap = new Map();
        if (sfdt.comments && sfdt.comments.length) {
          sfdt.comments.forEach((comment) => {
            // Word has a bug where sometimes it will add empty comments to the document,
            // so if we come across an empty comment we can just ignore it.
            if (
              comment?.blocks?.length &&
              comment?.blocks[0]?.inlines?.length
            ) {
              const commentId = comment.commentId;
              if (sfdtCommentsMap.has(commentId)) {
                throw new Error(
                  `A comment with ID ${commentId} already exists.`
                );
              }
              sfdtCommentsMap.set(comment.commentId, comment);

              if (TESTING) {
                comment.commentId = uuid();
                sfdtCommentsMap.set(uuid(), comment);
              }
            }
          });
        }

        /** @type {Set<String>} */
        let ongoingCommentsSet = new Set();
        // List information high-level.
        /** @type {listInfo} */
        const listInfo = {
          listId: -1,
          key: "",
          clauseKey: "",
        };
        // Lists already accessed on loop.
        /** @type {listInfo[]} */
        const addedLists = [];

        for (const section of sections) {
          const { blocks, headersFooters, sectionFormat } = section;

          const sectionNode = $createSectionNode({
            metadata: {
              headersFooters: JSON.parse(JSON.stringify(headersFooters)),
              sectionFormat: JSON.parse(JSON.stringify(sectionFormat)),
            },
          });

          ongoingCommentsSet = parseBlocksAndPush(
            blocks,
            sfdt,
            sfdtCommentsMap,
            ongoingCommentsSet,
            user,
            agreement,
            metadata,
            sectionNode,
            section,
            { listInfo, addedLists },
            organizationUsers
          );

          root.append(sectionNode);
        }

        // Resolve the promise after all the editor updates.
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  });

  const editorState = editor.getEditorState();
  const serializedEditorState = editorState.toJSON();

  // Handle footer and header.
  let serializedFooter;
  let serializedHeader;

  for (const originalSection of sections) {
    const section = JSON.parse(JSON.stringify(originalSection));
    /** @type {import("../types/sfdt").Block[]} */
    let headerBlocks;
    /** @type {import("../types/sfdt").Block[]} */
    let footerBlocks;

    if (
      section.headersFooters &&
      Object.keys(section.headersFooters).length &&
      !headersAndFootersIsEmpty(section.headersFooters)
    ) {
      const isDifferentFirstPage = section.sectionFormat.differentFirstPage;
      if (!isDifferentFirstPage) {
        headerBlocks = section.headersFooters.header?.blocks;
        footerBlocks = section.headersFooters.footer?.blocks;
      } else {
        headerBlocks = section.headersFooters.firstPageHeader?.blocks;
        footerBlocks = section.headersFooters.firstPageFooter?.blocks;
      }

      const editorHeader = createEditor(config),
        editorFooter = createEditor(config);

      const footerHeaderArr = [
        { editor: editorHeader, blocks: headerBlocks },
        { editor: editorFooter, blocks: footerBlocks },
      ];

      for (let index = 0; index < footerHeaderArr.length; index++) {
        const { editor, blocks } = footerHeaderArr[index];

        GlobalListHandler.reset();

        await new Promise((resolve) => {
          editor.update(() => {
            const root = $getRoot();
            /** @type {listInfo} High-level list information. */
            const listInfo = {
              listId: -1,
              key: "",
              clauseKey: "",
            };
            // Lists already accessed on loop.
            const /** @type {listInfo[]} */ addedLists = [];

            parseBlocksAndPush(
              blocks,
              sfdt,
              new Map(),
              new Set(),
              user,
              agreement,
              {},
              root,
              section,
              { listInfo, addedLists },
              organizationUsers
            );
            resolve(true);
          });
        });
      }

      const editorStateHeader = editorHeader.getEditorState();
      serializedHeader = editorStateHeader.toJSON();

      const editorStateFooter = editorFooter.getEditorState();
      serializedFooter = editorStateFooter.toJSON();
    }
  }

  const result = {
    agrTypeID,
    content: serializedEditorState,
    listsStructure: sfdt.abstractLists,
    metadata,
    header: serializedHeader,
    footer: serializedFooter,
  };

  if (!sfdt.defaultSfdt && process.env.NODE_ENV !== "production") {
    console.info("%cSerialized Lexical:", "color: gold");
    console.info(JSON.parse(JSON.stringify(serializedEditorState)));
  }

  // Make sure to restore original styles and abstract lists as we do not currently allow them to be changed.
  sfdt.styles = originalStyles;
  sfdt.abstractLists = originalAbstractLists;

  return result;
}
